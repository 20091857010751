html, body {
  height: 100%; /* Make sure the html and body are taking the full height of the viewport */
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100%; /* Ensure the root element also takes up at least the viewport height */
}

main {
  flex: 1; /* Allows the main content to expand and fill the space, pushing the footer down */
}