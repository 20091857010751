header {
    background: url('../../images/header-bg-image-4-2.jpg') no-repeat center center/cover;
    color: #fff;
    text-align: center;
    padding: 10px 0;
    margin: 0;
    width: 100%;
    font-family: 'Helvetica', sans-serif;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
}

header a {
    text-decoration: none;
    color: inherit;
}

header h1 {
    margin: 0;
    padding: 0;
    font-size: 2rem;
    color: white;
    text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.6), -2px -2px 3px rgba(0, 0, 0, 0.6);
}

ul {
    list-style: none;
    padding-left: 0;
}
