* {
    box-sizing: border-box;
}

html, body {
    height: 100%;
    margin: 0;
    padding: 0;
}

body {
    font-family: Arial, sans-serif; 
    display: flex;
    flex-direction: column;
}

a:link, a:visited {
    color: darkorange;
    text-decoration: none;
}

.siteContent {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
}

main {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.mainContent {
    flex: 1;
    padding-top: 70px;
    padding: 7px 0;
    padding-bottom: 0;
}

h1 {
    color: #fff;
    text-shadow: 
        2px 2px 3px rgba(0, 0, 0, 0.6),
        -2px -2px 3px rgba(0, 0, 0, 0.6);
}

.contentGrid {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    padding-top: 15px;
}

.contentCard {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    flex: 0 0 calc(33.333% - 20px);
    padding-bottom: 14px;
    line-height: 1.5;
}

.contentCard img {
    width: 100%;
    height: auto;
}

.contentCardBody {
    padding: 15px;
    padding-bottom: 7px;
}

.contentCardBody p {
    color: #333;
}

.contentCardTitle {
    margin: 0 0 10px;
    font-size: 1.25rem;
}

@media (max-width: 900px) {
    .contentCard {
        flex: 0 0 calc(50% - 20px);
    }
}

@media (max-width: 600px) {
    .contentCard {
        flex: 0 0 100%;
    }
}
