.hamburgerMenu {
    color: white;
    cursor: pointer;
    right: 15px;
    position: absolute;
    top: 0px;
    font-size: 22px;
    font-weight: bold;
    user-select: none;
    text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.6), -2px -2px 3px rgba(0, 0, 0, 0.6);
    padding: 15px;
}

.navLinks {
    display: none;
    position: absolute;
    right: -15px;
    top: 65px;
    background-color: #f9f9f9;
    border: 1px solid #ccc;
    border-radius: 5px;
    z-index: 1000;
    width: 140px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    margin-top: -20px;
    padding-left: 0;
    margin-left: 0;
    font-size: 16px;
    font-weight: normal;
    font-family: Arial, sans-serif;
}

.navLinks li a {
    display: block;
    text-decoration: none;
    color: #333;
    padding: 12px 16px;
    text-align: center;
    text-shadow: none;
    font-size: 14px;
    font-weight: normal;
}

.navLinks li a:hover {
    background-color: #f2f2f2;
}

.hamburgerMenu:hover + .navLinks,
.navLinks:hover {
    display: block; 
}

@media screen and (max-width: 768px) {
    .hamburgerMenu {
        display: block;
    }

    .navLinks {
        display: none;
        position: fixed;
        top: 75px;
        left: 0;
        right: 0;
        width: 100%;
        background-color: #fff;
        box-sizing: border-box;
        z-index: 2000;
    }

    .navLinks.show {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .navLinks li {
        display: block;
        width: 100%;
        margin: 0;
        padding: 8px 20px;
        border-bottom: 1px solid #cccccc;
    }

    .navLinks li:last-child {
        border-bottom: none;
    }

    .navLinks li a {
        text-align: center;
    }

    .hidden {
        display: none;
    }
}
