a:link, a:visited {
  color: #EB7600;
  text-decoration: none;
}

p {
  margin-top: 5px;
  margin-bottom: 0px;
}

.contentContainer {
  width: calc(100% - 40px);
  max-width: 800px;
  margin: 40px auto;
  padding: 20px 20px;
  box-sizing: border-box;
  background-color: white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  line-height: 1.5;
}

ol {
  list-style: none;
  padding-left: 0;
}

.articleIntro {
  text-align: left;
  padding: 0;
  font-size: 18px;
  color: #555;
  border-bottom: 1px solid #eee;
  padding-bottom: 15px;
  margin-bottom: 0px;
}

h1 {
  font-size: 2rem;
  margin: 0;
  padding: 0;
  background: none;
  text-shadow: none;
  word-wrap: break-word;
  overflow-wrap: break-word;
  hyphens: auto;
  text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.6), -2px -2px 3px rgba(0, 0, 0, 0.6);
}

@media (min-width: 769px) {
  .listItemNumber {
      display: none;
  }
}

@media (max-width: 768px) {
  .contentContainer {
      padding: 10px 20px;
      margin: 20px auto;
  }

  .listItemNumber {
      display: none;
      font-size: 24px;
      width: 50px;
      height: 50px;
      line-height: 50px;
      background-color: #333;
      color: #fff;
      text-align: center;
      border-radius: 50%;
      box-sizing: border-box;
      font-weight: normal;
  }

  .listItemNumberInline {
      font-weight: normal;
  }
}

.articleTitle {
  font-size: 1.5rem;
  color: #333;
  margin: 20px 0;
  text-align: left;
  padding: 0;
}

.listItem {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-bottom: 1px solid #eee;
  padding-top: 20px;
  padding-bottom: 7px;
}

.listItem p {
  font-size: 16px;
}

.listItem:first-child {
  padding-top: 5px;
}

.articleIntro,
.listItem p,
.listItemContent,
.listItemTitle {
  color: black;
}

.listItem ul {
  padding-left: 0;
  list-style-type: none;
}

.listItem:last-child {
  border-bottom: 0;
}

h2 {
  display: inline;
  margin-left: 20px;
  vertical-align: top;
  font-size: 24px;
}

.listItem h2 {
  font-size: 24px;
  margin: 0;
  display: inline-block;
  vertical-align: middle;
}

.listItemTitle {
  font-weight: bold;
  margin-bottom: 5px;
  font-size: 16px;
}

.subItem {
  margin-bottom: 10px;
}

.listItemContent {
  display: block;
  margin-left: 0;
  font-size: 16px;
}

.listItemNumberInline {
  background-color: #333;
  color: #fff;
  font-size: 24px;
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  border-radius: 50%;
  margin-right: 15px;
  float: left;
  position: relative;
  top: -7px;
}

.clear {
  clear: both;
}

.listItem + .listItem {
  margin-top: 5px;
}

.listItemImage {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 20px 0;
}

.listItemImage,
.subItem {
  clear: both;
}

.pageTitle {
  font-size: 2rem;
  color: #333;
  text-align: center;
  margin: 30px 0;
  text-shadow: none;
}
