.contentContainer {
  width: calc(100% - 40px);
  max-width: 800px;
  margin: 40px auto;
  padding: 20px 20px;
  box-sizing: border-box;
  background-color: white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  line-height: 1.5;
}  

.pageTitle {
  font-size: 2rem;
  color: #333;
  text-align: center;
  margin: 30px 0;
  text-shadow: none;
}

.articleIntro {
  text-align: left;
  padding: 0;
  font-size: 18px;
  color: #555;
}

ul {
  font-size: 18px;
  color: #555;
}
