footer {
    background-color: #333;
    color: #fff;
    text-align: center;
    padding: 12px;
    margin-top: 20px;
}

footer p {
    line-height: 1.2;
}